import { ComplainService} from '@/modules/complain/complain-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_FIND = 'find';
export const DO_FIND_FOR_STAFF = 'findForStaff';
export const DO_CLASSIFICATION = 'classification';
export const DO_RESOLVE = 'resolve';
export const DO_CLEAR = 'clear';
export const DO_UPLOAD = 'upload';
export const DO_DOWNLOAD = 'download';
export const DO_FIND_NOTIFICATION_TEMPLATE = 'doFindNotificationTemplate';
export const GET_UPLOAD_TOKEN = 'getUploadToken';

// mutation types
export const FIND_SUCCESS = 'findSuccess';
export const RESET = 'reset';
export const SET_UPLOAD_TOKEN = 'setUploadToken';

const state = {
    record: null,
    fileDownloadAccessToken: null,
    uploadToken: null,
};

const getters = {
    record: (state) => state.record,
    fileDownloadAccessToken: (state) => state.fileDownloadAccessToken,
    uploadAccessToken: (state) => state.uploadToken,
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/create', { root: true });
            ComplainService.create(values)
                .then((data) => {
                    resolve(data.data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/update', { root: true });
            ComplainService.update(id, values)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/update', { root: true });
                });
        });
    },
    [DO_FIND](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/find', { root: true });
            ComplainService.find(id)
                .then(({ headers, data }) => {
                    const fileDownloadAccessToken = headers['x-file-download-access-token'];
                    context.commit(FIND_SUCCESS, { fileDownloadAccessToken, data });
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/find', { root: true });
                });
        });
    },
    [DO_FIND_FOR_STAFF](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/find', { root: true });
            ComplainService.findForStaff(id)
                .then(({ headers, data }) => {
                    const fileDownloadAccessToken = headers['x-file-download-access-token'];
                    context.commit(FIND_SUCCESS, { fileDownloadAccessToken, data });
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/find', { root: true });
                });
        });
    },
    [DO_CLEAR](context) {
        context.commit(RESET);
    },
    [DO_CLASSIFICATION](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/update', { root: true });
            ComplainService.updateStaff(id, values)
                .then(() => {
                    Message.success(i18n.t('COMPLAIN.NOTIFIERS.CLASSIFICATION_SUCCESS_TEXT'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/update', { root: true });
                });
        });
    },
    [DO_RESOLVE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/update', { root: true });
            ComplainService.updateStaff(id, values)
                .then(() => {
                    Message.success(i18n.t('COMPLAIN.NOTIFIERS.RESOLVE_SUCCESS_TEXT'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/update', { root: true });
                });
        });
    },
    [DO_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/upload', { root: true });
            const { uploadAccessToken, files } = payload;
            ComplainService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/upload', { root: true });
                });
        });
    },
    [DO_DOWNLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/update', { root: true });
            const { fileDownloadAccessToken, id } = payload;
            ComplainService.download(fileDownloadAccessToken, id)
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/update', { root: true });
                });
        });
    },
    [DO_FIND_NOTIFICATION_TEMPLATE](context, status) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'complain/notificationTemplate', { root: true });
            ComplainService.findNotificationTemplate({ params: {type : `${status}_complain_notification`} })
                .then((data) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'complain/notificationTemplate', { root: true });
                });
        });
    },
    [GET_UPLOAD_TOKEN](context) {
        return new Promise((resolve, reject) => {
            ComplainService.getUploadToken()
                .then((response) => {
                    if (response.headers['x-file-upload-access-token']) {
                        context.commit(SET_UPLOAD_TOKEN, response.headers['x-file-upload-access-token']);
                    }
                    resolve();
                })
                .catch(({ error }) => {
                    Errors.handle(error);
                    reject(error);
                })
        });
    },
};

const mutations = {
    [FIND_SUCCESS](state, payload) {
        const { fileDownloadAccessToken, data } = payload;
        state.fileDownloadAccessToken = fileDownloadAccessToken;
        state.record = data;
    },
    [RESET](state) {
        state.fileDownloadAccessToken = null;
        state.record = null;
    },
    [SET_UPLOAD_TOKEN](state, payload) {
        state.uploadToken = payload;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
