import Errors from '@/shared/error/errors';
import { ComplainService} from '@/modules/complain/complain-service';

// action types
export const GET_ACTIVITIES = 'getActivities';
export const SEND_COMMENT = 'sendComment';

// mutation types
export const SET_ACTIVITIES = 'setActivities';

const state = {
    activities: [],
};

const getters = {
    activities: (state) => state.activities,
};

const actions = {
    [GET_ACTIVITIES](context, id) {
        context.commit('shared/activateLoading', 'complains/get', { root: true });
        return new Promise((resolve, reject) => {
            ComplainService.getActivities(id)
            .then(({ data }) => {
                context.commit(SET_ACTIVITIES, data);
                resolve(data);
            })
            .catch(({ error }) => {
                Errors.handle(error);
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'complains/get', { root: true });
            })
        });
    },
    [SEND_COMMENT](context, params) {
        context.commit('shared/activateLoading', 'complains/sendComment', { root: true });
        return new Promise((resolve, reject) => {
            // For now only sendStaffComment is available - comments are send only by staff
            const method = params.loadStaff ? 'sendStaffComment' : 'sendComment';

            ComplainService[method](params)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ error }) => {
                Errors.handle(error);
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'complains/sendComment', { root: true });
            })
        });
    }
};

const mutations = {
    [SET_ACTIVITIES](state, payload) {
        state.activities = payload;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
